import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import {JsonTab} from "./components/Tab";
import {JSONEditor} from "./components/Editor";
import {DBConfig} from "./config/DBConfig";
import {initDB, useIndexedDB} from "react-indexed-db";
import {ErrorBoundary} from "./ErrorBoundary";
import {Header} from "./components/Header";
import {Footer} from "./components/Footer";
import {TabNameDialog} from "./components/TabNameDialog";
import {useEffect, useState} from "react";
import Drawer from '@mui/material/Drawer';
import {AvailableTabList} from "./components/AvailableTabList";
import store from "./store/store";
import {addTabs, fetchTabs} from "./store/feature/tabs/tabSlice";
import axios from "axios";

initDB(DBConfig);

function App() {
    const [open, setOpen] = useState(false);
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const jsonDb = useIndexedDB("json");

    useEffect(() => {
        store.dispatch(fetchTabs(jsonDb))
        checkIfAnySharedLink()
    }, [])
    const openTabNameModal = () => {
        setOpen(true);
    };

    const toggleTabs = () => {
        setDrawerOpen(!isDrawerOpen);
    }

    const checkIfAnySharedLink = () => {
        const params = new URL(document.location).searchParams;
        if (params.has('shared')) {
            console.log(`got shared url`, params.has('shared'), params.get('shared'))
            getSharedTab(params.get('shared')).then();
        }
    }

    const getSharedTab = async (tabId) => {
        if(!tabId) return;
        try {
            const url = `/.netlify/functions/shared/${tabId}`;
            const response = await axios.get(url);
            const tab = response?.data?.json_document;

            store.dispatch(addTabs({db: jsonDb, newTab: tab}));
        } catch(e) {
            console.error(e);
        }
    }

    return (
        <div>
            <ErrorBoundary>
                <Header toggleTabs={toggleTabs}/>
                <div className="app-wrapper container">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card variant="outlined">
                                <JsonTab openRenameTab={openTabNameModal}/>
                                <JSONEditor/>
                                <TabNameDialog open={open} setOpen={setOpen}/>
                            </Card>
                        </Grid>
                    </Grid>
                    <Drawer
                        anchor='left'
                        open={isDrawerOpen}
                        onClose={() => setDrawerOpen(false)}
                    >
                        <AvailableTabList/>
                    </Drawer>
                </div>
                <Footer/>
            </ErrorBoundary>
        </div>
    );
}

export default App;
