import GitHubIcon from '@mui/icons-material/GitHub';

export function Header({toggleTabs}) {

    return (
        <div className="container">
            <header className="header-wrapper">
                <h2 className={'logo'}>JSONIFY</h2>
                <div className="menu">
                    <span onClick={toggleTabs} role={"button"} aria-controls={'tabs-list'} aria-label={'tab'}>Tabs</span>
                    <a href="https://github.com/sanjusudheerm/jsonify" target="_blank" className="link">
                        <GitHubIcon/>
                    </a>
                </div>
            </header>
        </div>
    );
}
