import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import store from "../store/store";
import {useSelector} from "react-redux";
import {setCurrentTab, updateTabs} from "../store/feature/tabs/tabSlice";
import {IconButton, Snackbar} from "@mui/material";
import {ContentCopy} from "@mui/icons-material";
import axios from "axios";
import {useIndexedDB} from "react-indexed-db";
import copy from 'copy-to-clipboard';
import {useState} from "react";

export function AvailableTabList() {
    const tabsList = useSelector(state => state.tabs.tabList)
    const activeTab = useSelector(state => state.tabs.currentTab)
    const [open, setOpen] = useState(false);
    const jsonDb = useIndexedDB("json");

    const checkIfUrlExist = (tab) => {
        try {
            if (!(tab.sharedUrlCreatedAt && tab.sharedUrl.length > 0)) throw new Error('No shared url')
            const diff = new Date().getTime() - new Date(tab.sharedUrlCreatedAt).getTime();
            const hours = Math.floor(diff / (1000 * 60 * 60 * 24));
            if (hours < 1) {
                copy(tab.sharedUrl);
                openNotification();
            } else {
                throw new Error('Shared URL expired')
            }
        } catch (e) {
            console.log('generating new URL')
            generatePublicUrl(tab).then()
        }
    }
    const generatePublicUrl = async (tab) => {

        try {
            const response = await axios.post('/.netlify/functions/create-public-url', tab)
            console.log('on success: ', response);
            const updateTab = {...tab, sharedUrl: response.data.url, sharedUrlCreatedAt: new Date().getTime()};
            copy(updateTab.sharedUrl);
            store.dispatch(updateTabs({db: jsonDb, tab: updateTab}));
            openNotification();
        } catch (e) {
            console.log(e)
        }
    };

    const openNotification = ()=> {
        setOpen(true);
        setTimeout(() => {
            setOpen(false)
        }, 6000);
    }


    return (
        <>
            <div className={'logo-wrapper'}>
                <h2 className={'logo'}>JSONIFY</h2>
            </div>
            <List sx={{width: 300, maxWidth: 360, bgcolor: 'background.paper'}}
                  area-labelledby={'tabs'} id={'tabs-list'}>
                {tabsList?.map((res) => {
                    return <ListItem
                        onClick={() => {
                            store.dispatch(setCurrentTab(res));
                        }} key={res.tabId}
                        id={res.tabId}
                        secondaryAction={
                            <IconButton aria-label="copy public url"
                                        onClick={() => checkIfUrlExist(res)}>
                                <ContentCopy/>
                            </IconButton>
                        }
                        className={activeTab.tabId === res.tabId ? 'active' : ''}
                    >
                        <ListItemAvatar>
                            <Avatar src={'/jsonify.png'}/>
                        </ListItemAvatar>
                        <ListItemText primary={res.name}/>
                    </ListItem>
                })}
            </List>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                message="Sharable URL has been copied to clipboard"
            />
        </>
    );
}
