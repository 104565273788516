import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import {useState} from "react";
import {setCurrentTab, updateTabs} from "../store/feature/tabs/tabSlice";
import {useSelector} from "react-redux";
import store from "../store/store";
import {useIndexedDB} from "react-indexed-db";

export function TabNameDialog({open, setOpen}) {
    const currentTab = useSelector(state => state.tabs.currentTab);
    const [name, setName] = useState(currentTab?.name);
    const jsonDb = useIndexedDB("json");
    const handleClose = () => {
        setOpen(false);
        console.log(currentTab, name, {...currentTab, name})
        store.dispatch(updateTabs({db: jsonDb, tab: {...currentTab, name}}))
        store.dispatch(setCurrentTab({...currentTab, name}))
    };

    const getTabName = (e) => {
        setName(e.target.value);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add Tab Name</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Update Tab name, which helps you to easily categorize the items
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Tab Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e) => getTabName(e)}
                    value={name}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleClose}>Update</Button>
            </DialogActions>
        </Dialog>
    );
}
