import {useCallback, useEffect, useRef} from "react";
import {v4 as uuidv4} from "uuid";
import {useIndexedDB} from "react-indexed-db";
import {useSelector} from "react-redux";
import {
  addTabs,
  removeTabs,
  setCurrentTab,
} from "../store/feature/tabs/tabSlice";
import store from "../store/store";

export function JsonTab({openRenameTab}) {
  const currentTab = useSelector((state) => state.tabs.currentTab);
  const tabList = useSelector((state) => state.tabs.tabList);
  const jsonDb = useIndexedDB("json");
  const tabArea = useRef(null);

  useEffect(() => {
    if (tabList && tabList.length === 0) {
      const tab = {
        ...getNewTab(),
        createdAt: new Date().getTime(),
        updatedAt: new Date().getTime(),
      }
      store.dispatch(
        addTabs({
          db: jsonDb,
          newTab: tab
        })
      );
    }
  }, [tabList]);

  useEffect(() => {
    if (currentTab === null && tabList && tabList.length > 0) {
      store.dispatch(setCurrentTab(tabList[0]));
    }
  }, [currentTab, tabList]);

  function handleChange(tab) {
    console.log('on tab switch: ', tab)
    store.dispatch(setCurrentTab(tab));
  }

  const createNewTab = () => {
    const newTab = {
      ...getNewTab(),
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
    };
    store.dispatch(addTabs({db: jsonDb, newTab}));
  };

  const getNewTab = useCallback(function () {
    const tabId = uuidv4().substring(0, 8);
    return {
      name: `Untitled Tab`,
      tabId,
      data: "",
      id: `${tabId}`,
      sharedUrl: '',
      sharedUrlCreatedAt: ''
    };
  }, []);

  const removeTab = (id, removedTabIndex) => {

    if (currentTab.id === id) {
      store.dispatch(setCurrentTab(tabList[Math.abs(removedTabIndex - 1)]))
    }
    store.dispatch(removeTabs({db: jsonDb, id}));
  };

  const navigateTab = (direction) => {
    const value = direction === 0 ? -150 : 150;
    tabArea.current.scrollLeft += value;
  };

  return (
    <div className="tab-list-wrapper">
      <div className="tab-list hide-scrollbar" ref={tabArea}>
        {tabList?.map((tab, index) => (
          <div
            className={`tab ${
              tab?.tabId === currentTab?.tabId ? "active" : ""
            }`}
            key={tab?.tabId}
            onClick={() => handleChange(tab)}
            id={`tab-${tab?.tabId}`}
            onDoubleClick={() => openRenameTab(tab)}
          >
              <span className="tab-name">
                {tab?.name} {tab?.order}
              </span>
            {tabList.length > 1 && (
              <span className="tab-icon" onClick={(e) => {
                e.stopPropagation()
                removeTab(tab?.id, index)
              }}>
                  <em className="material-icons">close</em>
                </span>
            )}
          </div>
        ))}
      </div>
      <div className="tab-navigation left" onClick={() => navigateTab(0)}>
        <em className="material-icons">chevron_left</em>
      </div>
      <div className="tab-navigation right" onClick={() => navigateTab(1)}>
        <em className="material-icons">chevron_right</em>
      </div>
      <div className="tab static" onClick={createNewTab}>
        <em className="material-icons">add</em>
      </div>
    </div>
  );
}
