import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

export const tabSlice = createSlice({
    name: "tabs",
    initialState: {
        tabList: [],
        currentTab: null,
    },
    reducers: {
        setCurrentTab: (state, action) => {
            state.currentTab = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchTabs.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchTabs.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.tabList = sortOnCreatedDate([...action.payload]);
            })
            .addCase(fetchTabs.rejected, (state, action) => {
                state.status = "failed";
                state.error = action?.error.message;
            })
            .addCase(removeTabs.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(removeTabs.fulfilled, (state, action) => {
                state.status = "succeeded";
                const newTabList = state.tabList.filter(
                    (tab) => tab.id !== action.payload
                );
                state.tabList = [...newTabList];
            })
            .addCase(removeTabs.rejected, (state, action) => {
                state.status = "failed";
                state.error = action?.error.message;
            })
            .addCase(addTabs.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(addTabs.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.tabList = sortOnCreatedDate([...state.tabList, action.payload]);
            })
            .addCase(addTabs.rejected, (state, action) => {
                state.status = "failed";
                state.error = action?.error.message;
            })
            .addCase(updateTabs.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(updateTabs.fulfilled, (state, action) => {
                state.status = "succeeded";
                const newTabList = state.tabList.filter(
                    (tab) => tab.id !== action.payload.id
                );
                state.tabList = sortOnCreatedDate([...newTabList, action.payload]);
            })
            .addCase(updateTabs.rejected, (state, action) => {
                state.status = "failed";
                state.error = action?.error.message;
            });
    },
});

export const {addNewTab, removeTab, updateTab, setCurrentTab} =
    tabSlice.actions;
export default tabSlice.reducer;

export const fetchTabs = createAsyncThunk("getTabs", async (db) => {
    const response = await db.getAll();
    return response;
});

export const removeTabs = createAsyncThunk("removeTab", async (payload) => {
    await payload.db.deleteRecord(payload.id);
    return payload.id;
});

export const addTabs = createAsyncThunk("addNewTab", async (payload) => {
    try {
        await payload.db.add(payload.newTab);
        return payload.newTab;
    } catch (e) {
        console.error('on error', e)
    }
});

export const updateTabs = createAsyncThunk("updateTab", async (payload) => {
    await payload.db.update(payload.tab);
    return payload.tab;
});

export const sortOnCreatedDate = (arr) =>
    arr.sort((a, b) => a.createdAt - b.createdAt);
