import Editor from "@monaco-editor/react";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {updateTabs} from "../store/feature/tabs/tabSlice";
import store from "../store/store";
import {useIndexedDB} from "react-indexed-db";

export function JSONEditor() {

    const currentTab = useSelector(state => state.tabs.currentTab);
    const [editorData, setEditorData] = useState('');
    const jsonDb = useIndexedDB("json");
    const options = {
        lineHeight: 25,
        fontSize: 16,
        minimap: {
            enabled: false
        },
        autoIndent: 'advanced',
        formatOnPaste: true
    }

    useEffect(() => {
        if (currentTab) {
            try {
                setEditorData(atob(currentTab?.data));
            } catch (e) {
                console.error(e)
            }
        }
    }, [currentTab])


    function updateData(value) {
        if (currentTab) {
            const data = {...currentTab, data: btoa(value), updatedAt: new Date().getTime()};
            store.dispatch(updateTabs({db: jsonDb, tab: data}));
        }
    }

    return (
        <div className="editor">
            <Editor
                height="80vh"
                defaultLanguage="json"
                value={editorData}
                options={options}
                onChange={updateData}
            />
        </div>
    );
}
